import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import qs from 'query-string'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useQueryState } from 'react-router-use-location-state'
// api
import { dateFromTOdateTO } from '../../../helpers/helper'
import UsersListMainHeader from '../../Users/Component/UsersListMainHeader'
import { getOrderList, getOrdersTotalCount } from '../../../actions/orders'
import OrderList from './OrderList'
import OrderListHeader from './OrderListHeader'
import getDynamicOrderStatus from '../../../api/OrderManagement/orderFilter'
import { useQuery } from '@tanstack/react-query'
// import { getEventDetails } from '../../../actions/events'
// import SocketContext from '../../../context/SocketContext'

function OrderManagement (props) {
  const location = useLocation()
  const {id,orderId} = useParams()
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('userId')
  const pendingOrderStatus = searchParams.get('orderStatus')
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])
  const [initialFlag, setinitialFlag] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [platform, setPlatform] = useQueryState('ePlatform', '')
  const [utmSource, setUtmSource] = useQueryState('sUtmSource', '')
  const [columnNames, setColumnNames] = useState()
  const [eventOrderStatus, setEventOrderStatus] = useState()
  const [eventBidType, setEventBidType] = useState()
  const [eventOrderType, setEventOrderType] = useState()
  const [bIsAdminOrders, setBIsAdminOrders] = useState()
  // const [isCreate, setIsCreate] = useState(true)
  // const [ isEdit,setIsEdit] = useState(false)
  // const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const eventDetails = useSelector(state => state?.events?.eventDetails)
  const token = useSelector((state) => state.auth.token)
  const ordersTotalCount = useSelector(state => state.orders.orderTotalCount)
  const resStatus = useSelector(state => state.orders.resStatus)
  const resMessage = useSelector(state => state.orders.resMessage)
  const orderList = useSelector((state) => state.orders.orderList)
  const content = useRef()
  const { data: orderStatus } = useQuery({
    queryKey: ['getDynamicOrderStatus'],
    queryFn: () => getDynamicOrderStatus(),
    select: (res) => res?.data?.data,
  })
  // useEffect(() => {
  //   if (socket) {
  //     console.log('Socket connected:', socket);
  //     socket.on('ADMIN_UPDATES', (data) => {
  //       console.log(data, 'Received ADMIN_UPDATES event');
  //     });
  //     // Cleanup event listeners on unmount
  //     return () => {
  //       socket.off('ADMIN_UPDATES');
  //     };
  //   }
  // }, [socket]); 

//   useEffect(() => {
//     if (id) {
//       dispatch(getEventDetails({ Id: id }))
//         // setIsCreate(false)
//     } else {
//         // setIsEdit(true)
//     }
// }, [socket,id])
  useEffect(() => {
    if (orderId) {
      // setIsCreate(false)
    } else {
      // setIsEdit(true)
    }
  }, [])
  useEffect(()=>{
      if(pendingOrderStatus){
        setBIsAdminOrders('user')
      }
  },[pendingOrderStatus])
  useEffect(() => {
    const obj = qs.parse(location.search)
    setSearch(obj.searchvalue || '')
    setDateRange([obj.datefrom ? new Date(obj.datefrom) : null, obj.dateto ? new Date(obj.dateto) : null])
    setPlatform(obj.ePlatform || '')
    setUtmSource(obj.sUtmSource || '')
    getDefaultColumn()
}, [location.search])
useEffect(()=>{
  if(pendingOrderStatus){
    setEventOrderStatus(pendingOrderStatus)
  }
},[pendingOrderStatus])
  // Function to handle search
  function onHandleSearch (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
    setSearch(e.target.value)
    setinitialFlag(true)
  }

  // Function to handle other filters
  function handleOtherFilter (e, type) {
    if(type==='seOrderStatus'){
      setEventOrderStatus(e?.target?.value)
    }else if(type==='eBidType'){
      setEventBidType(e?.target?.value)
    }else if(type==='eOrderType'){
      setEventOrderType(e?.target?.value)
    }else if(type==='bIsAdminOrders'){
      setBIsAdminOrders(e?.target?.value)
    }
    else{
      setFilter(e?.target?.value || [])
    }
  }

  // Function to fetch total count of users
  function getOrderTotalCount (searchvalue, filterBy, startDate, endDate) {
    const dateFrom = startDate ? new Date(moment(startDate).startOf('day').format()) : ''
    const dateTo = endDate ? new Date(moment(endDate).endOf('day').format()) : ''
    const data = { searchvalue, filterBy, startDate: dateFrom ? new Date(dateFrom).toISOString() : '', endDate: dateTo ? new Date(dateTo).toISOString() : '',  token,id,eventOrderStatus,eventBidType,eventOrderType,bIsAdminOrders,userId }
    dispatch(getOrdersTotalCount(data))
    
  }

  // Function to fetch list of users
  function getList (start, limit, sort, order, searchvalue, filterBy, startDate, endDate, isFullResponse) {
    const dateFrom = startDate ? new Date(moment(startDate).startOf('day').format()) : ''
    const dateTo = endDate ? new Date(moment(endDate).endOf('day').format()) : ''
    const orderData = { start, limit, sort, order, searchvalue: searchvalue.trim(), filterBy, startDate: dateFrom ? new Date(dateFrom).toISOString() : '', endDate: dateTo ? new Date(dateTo).toISOString() : '', platform, utmSource, isFullResponse, token,id ,eventOrderStatus,eventBidType,eventOrderType,bIsAdminOrders,userId}
    dispatch(getOrderList(orderData))
  }
  // Function to export data
  function onExport () {
    content.current.onExport()
  }
  // Function to refresh data
  function onRefreshFun () {
    content.current.onRefresh()
  }

  // Function to set default columns for active users
  function getDefaultColumn () {
    const data = [
      { sColumnName: 'No.', bShow: true },
      { sColumnName: 'UserName', bShow: true },
      { sColumnName: 'Event Name', bShow: true },
      { sColumnName: 'Category', bShow: true },
      { sColumnName: 'Bid Type', bShow: true },
      { sColumnName: 'Order Type', bShow: true },
      { sColumnName: 'Quantity', bShow: true },
      // { sColumnName: 'Remaining Quantity', bShow: true },
      { sColumnName: 'Buy Type', bShow: true },
      { sColumnName: 'Price', bShow: true },
      { sColumnName: 'FilledQTY', bShow: true },
      { sColumnName: 'Order Status', bShow: true },
      // { sColumnName: 'Book Profit', bShow: true },
      // { sColumnName: 'Stop Loss', bShow: true },
      { sColumnName: 'Order Date', bShow: true },
      { sColumnName: 'Actions', bShow: !userId ? true :false },
    ]
    setColumnNames(data)
  }

 return (
    <>
      <main className='main-content'>
        <section className='management-section common-box'>
          {userId && 
           <UsersListMainHeader
           heading={location?.pathname?.includes('/orders/trade-history') ? 'User Trade History' : ''}
           list={orderList}
           onExport={onExport}
           onRefresh={onRefreshFun}
           // refresh='Refresh Order Data'
           orders
           // buttonText="Add Order"
           // setUrl={`/order-management/add-order?eventId=${id}`}
         />
          }
         
          <div className={orderList?.results?.length === 0 ? 'without-pagination' : 'setting-component'}>
            <OrderListHeader
              dateRange={dateRange}
              endDate={endDate}
              filter={filter}
              handleOtherFilter={handleOtherFilter}
              handleSearch={onHandleSearch}
              list={orderList}
              normalUser
              search={search}
              searchBox
              setDateRange={setDateRange}
              startDate={startDate}
              totalCount={ordersTotalCount}
              heading='Orders'
              orders
              orderStatus={orderStatus}
              eventOrderStatus={eventOrderStatus}
              setEventOrderStatus={setEventOrderStatus}
              eventBidType={eventBidType}
              setEventBidType={setEventBidType}
              eventOrderType={eventOrderType}
              setEventOrderType={setEventOrderType}
              bIsAdminOrders={bIsAdminOrders}
              setBIsAdminOrders={setBIsAdminOrders}
              buttonText={eventDetails?.eStatus === 's' ? 'Add Order':''}
              setUrl={`/order-management/add-order?eventId=${id}`}
              buttonTextSell={eventDetails?.eStatus === 's' ? 'Sell Order':''}
              setUrlSell={`/order-management/sell-order?eventId=${id}`}
              userId
              pendingOrderStatus={pendingOrderStatus}
            />
            <OrderList
              {...props}
              ref={content}
              List={orderList}
              endDate={endDate}
              filter={filter}
              flag={initialFlag}
              getList={getList}
              getOrderTotalCount={getOrderTotalCount}
              resMessage={resMessage}
              resStatus={resStatus}
              search={search}
              setDateRange={setDateRange}
              setFilter={setFilter}
              setSearchProp={setSearch}
              setinitialFlag={setinitialFlag}
              startDate={startDate}
              ordersTotalCount={ordersTotalCount?.nCount || 0}
              viewLink='/users/user-management/user-details'
              platform={platform}
              setPlatform={setPlatform}
              dateFromTOdateTO={dateFromTOdateTO(dateRange)}
              utmSource={utmSource}
              setUtmSource={setUtmSource}
              columnNames={columnNames}
              eventOrderStatus={eventOrderStatus}
              eventBidType={eventBidType}
              eventOrderType={eventOrderType}
              bIsAdminOrders={bIsAdminOrders}
            />
          </div>
        </section>
      </main>
    
    </>

  )
}

OrderManagement.propTypes = {
  location: PropTypes.object
}

export default OrderManagement
