import { Fragment, useContext, useEffect, useState } from "react"
import { Col, Collapse, Row } from "reactstrap"
import caretBottom from '../../../assets/images/caret-top.svg'
import caretIcon from '../../../assets/images/caret-bottom.svg'
import OrderManagement from "../OrderManagement"
import OrderBook from "../OrderManagement/OrderBook"
import SocketContext from "../../../context/SocketContext"
import { useQuery } from "@tanstack/react-query"
import getOrderBook from "../../../api/OrderManagement/getOrderBook"
import { useParams, useSearchParams } from "react-router-dom"
import { getEventDetails } from "../../../actions/events"
import { useDispatch, useSelector } from "react-redux"
import { currencySymbol, dateFormate } from "../../../helpers/helper"

const EventDetails = (props) => {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const pendingOrderStatus = searchParams.get('orderStatus')

    const dispatch = useDispatch()
    const [bankModal, setBankModal] = useState(pendingOrderStatus ? true :false)
    const [orderBookModal, setOrderBookModal] = useState(false)
    const toggleBankModal = () => setBankModal(!bankModal)
    const toggleOrderBookModal = () => setOrderBookModal(!orderBookModal)
    const socket = useContext(SocketContext)
    const [activeTab, setActiveTab] = useState('BUY');
    const [newOrderBookList,setNewOrderBookList] = useState()
    const eventDetails = useSelector(state => state?.events?.eventDetails)
    console.log('eventDetails :>> ', eventDetails)

  const { data: orderBookList } = useQuery({
        queryKey: ['getOrderBook', id || props?.eventId, activeTab],
        queryFn: () => getOrderBook(id ||  props?.eventId, activeTab),
        select: (res) => res?.data?.data
    })
    useEffect(()=>{
        if(orderBookList?.length){
            setNewOrderBookList(orderBookList)
        }
    },[orderBookList])
    useEffect(() => {
        if (socket) {
          socket.on('ADMIN_UPDATES', (data) => {
            if(data?.eType === 'ORDER_BOOK_UPDATE'){
              setNewOrderBookList(data?.oData?.oOrderBook?.length ? data?.oData?.oOrderBook :orderBookList)
            }
            // if(activeTab==='BUY' && data?.eType ==='ORDER_BOOK_UPDATE'){
            //     setNewOrderBookList(data?.oData?.oOrderBook?.BUY?.length ? data?.oData?.oOrderBook?.BUY : orderBookList)
            //     // console.log(data, 'Received ADMIN_UPDATES event BUY',data?.oData?.oOrderBook?.BUY,data?.oData?.oOrderBook?.BUY?.length);
            // }else if(activeTab==='SELL' && data?.eType ==='ORDER_BOOK_UPDATE'){
            //     setNewOrderBookList(data?.oData?.oOrderBook?.SELL?.length ? data?.oData?.oOrderBook?.SELL : orderBookList)
            //     // console.log(data, 'Received ADMIN_UPDATES event SELL',data?.oData?.oOrderBook?.SELL,data?.oData?.oOrderBook?.SELL?.length);
            // }
          });
        }
      }, [socket]); 
      useEffect(() => {
        if (id) {
          dispatch(getEventDetails({ Id: id }))
        } 
    }, [socket,id])
    return <> 
    <main className='main-content d-flex'>
        <section className='user-section'>
          <Row>
            <Col className='p-0' lg={12} md={12} xl={12}>
              <Fragment>
                <div className='common-box-user'>
                  <div className='align-items-start user-heading' onClick={toggleBankModal}>
                    <h3>Orders</h3>
                    <span>
                      {' '}
                      <img alt="" src={bankModal ? caretBottom : caretIcon} />
                    </span>
                  </div>
                  <Collapse isOpen={bankModal}>
                    <OrderManagement />
                  </Collapse>
                  {eventDetails?.eStatus !== 'c' &&
                    <>
                      <div className='align-items-start user-heading' onClick={toggleOrderBookModal}>
                        <h3>Order Book</h3>
                        <span>
                          {' '}
                          <img alt="" src={bankModal ? caretBottom : caretIcon} />
                        </span>
                      </div>
                      <Collapse isOpen={orderBookModal}>
                        <OrderBook
                          orderBookList={newOrderBookList}
                          setActiveTab={setActiveTab}
                          activeTab={activeTab}
                        />
                      </Collapse>
                    </>
                  }
                </div>
              </Fragment>
            </Col>
          </Row>
          <h3 className='text-center mt-3 mb-0'>Event Details</h3>
          <div className='table-represent'>
            <div className='table-responsive'>
              <Row className="mb-4 d-flex">
                <Col md={12} xl={12}>
                  <Row>
                    <Col md={8}></Col>
                    <Col md={4} className="text-end">
                      <span className="bold-text" style={{ color: '#007bff' }}>Yes : {eventDetails?.aAnswerOptions[0]?.nCurrentPrice} {currencySymbol?.Indian} </span>
                      {/* <br /> */}
                      <span className="bold-text" style={{ color: 'red' }}>No : {eventDetails?.aAnswerOptions[1]?.nCurrentPrice} {currencySymbol?.Indian} </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Event Name</th>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Total Price</th>
                    <th>Price Difference</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='apilogs-tr'>
                    <td><b>{eventDetails?.sName || '--'}</b></td>
                    <td><b>{eventDetails?.oCategory?.sName || '--'}</b></td>
                    <td><b>{eventDetails?.oSubCategory?.sName || '--'}</b></td>
                    <td><b>{eventDetails?.nTotalPrice || '--'}</b></td>
                    <td><b>{eventDetails?.nPriceDiff || '--'}</b></td>
                    <td><b>{dateFormate(eventDetails?.dStartDate) || '--'}</b></td>
                    <td><b>{dateFormate(eventDetails?.dEndDate) || '--'}</b></td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div className='table-represent'>
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Yes Quantity</th>
                    <th>No Quantity</th>
                    <th>Min Buy Quantity</th>
                    <th>Max Buy Quantity</th>
                    <th>Admin Commission</th>
                    <th>Bonus</th>
                    <th>Outcome</th>
                    {/* <th>Sentiment</th> */}
                    <th>Matching Pattern</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='apilogs-tr'>
                    <td><b>{eventDetails?.aAnswerOptions[0]?.nInitialQty || '--'}</b></td>
                    <td><b>{eventDetails?.aAnswerOptions[1]?.nInitialQty || '--'}</b></td>
                    <td><b>{eventDetails?.oUserConfig?.nMinBuyQty ? `${eventDetails?.oUserConfig?.nMinBuyQty} ` : '--'}</b></td>
                    <td><b>{eventDetails?.oUserConfig?.nMaxBuyQty ? `${eventDetails?.oUserConfig?.nMaxBuyQty} ` : '--'}</b></td>
                    <td><b>{eventDetails?.nAdminBuyCommission ? `${eventDetails?.nAdminBuyCommission} %` : '--'}</b></td>
                    <td><b>{eventDetails?.nBonusUtil ? `${eventDetails?.nBonusUtil} %` : '--'}</b></td>
                    <td><b>{eventDetails?.sOutcomeValue ? `${eventDetails?.sOutcomeValue}` : '--'}</b></td>
                    {/* <td><b>{eventDetails?.aAnswerOptions[0]?.bIsSentiment===true ? 'YES' : 'NO'}</b></td> */}
                    <td><b>{eventDetails?.eMatchingPattern ? `${eventDetails?.eMatchingPattern}` : '--'}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
    </main>
    </>
}
export default EventDetails