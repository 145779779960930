import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Button, Modal, ModalBody, Row, Col, Input, Label, Badge } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useQueryState } from 'react-router-use-location-state'
import { useDispatch, useSelector } from 'react-redux'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import moment from 'moment'
import qs from 'query-string'
import PropTypes from 'prop-types'

import editButton from '../../assets/images/edit-pen-icon.svg'
import warningIcon from '../../assets/images/error-warning.svg'
import viewIcon from '../../assets/images/view-eye.svg'
import report from '../../assets/images/report.svg'
import deleteIcon from '../../assets/images/delete-bin-icon.svg'

import Loading from '../../components/Loading'
import PaginationComponent from '../../components/PaginationComponent'
import AlertMessage from '../../components/AlertMessage'
import DataNotFound from '../../components/DataNotFound'
import { modalMessageFunc } from '../../helpers/helper'
import { declareOutComeEvent, deleteEvent } from '../../actions/events'

const EventList = forwardRef((props, ref) => {
  const { List, resStatus, resMessage, getList, subCategoryId, flag, startDate, endDate, filter, usersTotalCount, eventDropdown, eventStatus, eventFilter, platform, utmSource, columnNames, categoryDropdown, categoryFilter } = props
  const location = useLocation()
  const exporter = useRef(null)
  const dispatch = useDispatch()
  const searchProp = props?.search
  const [isFullResponse] = useState(false)
  const [fullList, setFullList] = useState([])
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [startingNo, setStartingNo] = useState(0)
  const [endingNo, setEndingNo] = useState(0)
  const [total, setTotal] = useState(0)
  const [index, setIndex] = useState(1)
  const [list, setList] = useState([])
  const [activePageNo, setPageNo] = useQueryState('page', 1)
  const [declareOutCome, setDeclareOutCome] = useState('')
  const [declareOutComeErr, setDeclareOutComeErr] = useState('')
  const [isOpenEvent, setIsOpenEvent] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [dateFrom, setDateFrom] = useQueryState('dStartDate', '')
  // eslint-disable-next-line no-unused-vars
  const [dateTo, setDateTo] = useQueryState('dEndDate', '')
  // const [filterBy, setFilterBy] = useState('')
  const [order] = useQueryState('order', 'desc')
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [sort] = useQueryState('sortBy', 'dCreatedAt')
  const [search, setSearch] = useQueryState('searchvalue', '')
  const [listLength, setListLength] = useState('10 Rows')
  const [close, setClose] = useState(false)
  const [setSelectedData] = useState({})
  const [type, setType] = useState('')
  const [modalWarning, setModalWarning] = useState(false)
  const [modalWarningEvent, setModalWarningEvent] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const toggleWarning = () => setModalWarning(!modalWarning)
  const toggleWarningEvent = () => setModalWarningEvent(!modalWarningEvent)
  const isFullList = useSelector(state => state?.events?.isFullResponse)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const obj = qs.parse(location?.search)
  const deletedUsers = location?.pathname?.includes('deleted-users')
  const previousProps = useRef({ List, resStatus, resMessage, startDate, endDate, activePageNo, start, offset, filter, deletedUsers, platform, utmSource, eventDropdown, categoryDropdown, subCategoryId, eventStatus })?.current
  const paginationFlag = useRef(false)
  const navigate = useNavigate()
  // useEffect to set Query Param form url and also set Message from navigate
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.message) {
        setMessage(location?.state?.message)
        setStatus(true)
        setModalMessage(true)
      }
      navigate(location?.pathname, { replace: true })
    }
    let page = 1
    let limit = offset
    let searchText = ''
    if (obj) {
      if (obj?.page) {
        page = obj?.page
        setPageNo(page)
      }
      if (obj?.pageSize) {
        limit = obj?.pageSize
        setOffset(limit)
        setListLength(`${limit} Rows`)
      }
      if (obj?.searchvalue) {
        searchText = obj?.searchvalue
        setSearch(obj?.searchvalue)
      }

      if (!(obj?.dStartDate && obj?.dEndDate)) {
        const startFrom = (page - 1) * offset
        setStart(startFrom)
        getList(startFrom, limit, sort, order, searchText, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
      }
    }
    setLoading(true)
  }, [])
  // will be called when something searched
  useEffect(() => {
    const callSearchService = () => {
      const startFrom = 0
      const limit = offset
      getList(startFrom, limit, sort, order, props.search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
      setSearch(searchProp?.trim())
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    if (previousProps?.searchProp !== searchProp && flag) {
      const debouncer = setTimeout(() => {
        callSearchService()
      }, 1000)
      return () => {
        clearTimeout(debouncer)
        previousProps.searchProp = searchProp
      }
    }
    return () => {
      previousProps.searchProp = searchProp
    }
  }, [searchProp])

  useEffect(() => {
    if (previousProps?.filter !== filter) {
      const startFrom = start
      const limit = offset
      getList(startFrom, limit, sort, order, props?.search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    return () => {
      previousProps.filter = filter
    }
  }, [filter])
  useEffect(() => {
    if (previousProps?.subCategoryId !== subCategoryId) {
      const startFrom = 0
      const limit = offset
      getList(startFrom, limit, sort, order, props?.search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    return () => {
      previousProps.subCategoryId = subCategoryId
    }
  }, [subCategoryId])

  // handle to set startDate and EndDate
  useEffect(() => {
    if (previousProps?.startDate !== startDate || previousProps?.endDate !== endDate) {
      if (props?.startDate && props?.endDate) {
        const startFrom = (obj && obj?.dStartDate && obj?.dEndDate && obj?.page) ? (obj?.page - 1) * offset : 0
        const limit = offset
        getList(startFrom, limit, sort, order, search, props?.filter, props?.startDate, props?.endDate, platform, utmSource, isFullResponse)
        setDateFrom(new Date(moment(startDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))
        setDateTo(new Date(moment(endDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))

        if ((obj && obj?.dStartDate && obj?.dEndDate && obj?.page)) {
          setPageNo(obj?.page)
        } else {
          setPageNo(1)
        }
        setLoading(true)
      } else if ((!props?.startDate) && (!props?.endDate)) {
        const startFrom = 0
        const limit = offset
        getList(startFrom, limit, sort, order, search, props?.filter, props?.startDate, props?.endDate, platform, utmSource, isFullResponse)
        setDateFrom('')
        setDateTo('')
        setPageNo(1)
        setLoading(true)
      }
    }
    return () => {
      previousProps.startDate = startDate
      previousProps.endDate = endDate
    }
  }, [startDate, endDate])

  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])


  useEffect(() => {
    if (previousProps?.List !== List) {
      if (List && List?.results && !isFullList) {
        const userArrLength = List?.results?.length
        const startFrom = (activePageNo - 1) * offset + 1
        const end = startFrom - 1 + userArrLength
        setStartingNo(startFrom)
        setEndingNo(end)
        setList(List?.results)
        setIndex(activePageNo)
        setLoading(false)
      } else if (isFullList) {
        setFullList(List?.results ? List?.results : [])
        setLoading(false)
        exporter.current.props = {
          ...exporter?.current?.props,
          data: processExcelExportData(List?.results ? List?.results : []),
          fileName: 'Events.xlsx'
        }
        exporter?.current?.save()
        setLoader(false)
      }
    }
    setTotal(List?.total)
    return () => {
      previousProps.List = List
      previousProps.usersTotalCount = usersTotalCount
    }
  }, [List])

  // handle to set resMessage
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        if (resStatus) {
          const searchParams = new URLSearchParams(location.search);
          const page = searchParams.get('page') || 1;
          const startFrom = (page - 1) * offset;
          const limit = offset
          setPageNo(Number(page));
          getList(startFrom, limit, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
        } else {
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
          setLoading(false)
        }
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // useEffect to handle QueryParams
  useEffect(() => {
    let data = localStorage?.getItem('queryParams')
      ? JSON?.parse(localStorage?.getItem('queryParams'))
      : {}
    !Object?.keys(data)?.length
      ? (data = { EventManagement: location?.search })
      : (data.EventManagement = location?.search)
    localStorage?.setItem('queryParams', JSON?.stringify(data))
  }, [location?.search])
  useEffect(() => {
    if ((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current && start) {
      getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
      setLoading(true)
    } else if (previousProps?.offset !== offset) {
      getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
      setLoading(true)
    } else if (((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current)) {
      getList(0, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
      setLoading(true)
    }
    return () => {
      previousProps.start = start
      previousProps.offset = offset
    }
  }, [start, offset])

  useEffect(() => {
    if (previousProps?.eventDropdown !== eventDropdown) {
      const startFrom = 0
      getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse, eventFilter, categoryFilter)
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    return () => {
      previousProps.eventDropdown = eventDropdown
    }
  }, [eventDropdown])
  useEffect(() => {
    if (previousProps?.eventStatus !== eventStatus) {
      const startFrom = 0
      getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse, eventFilter, categoryFilter)
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    return () => {
      previousProps.eventStatus = eventStatus
    }
  }, [eventStatus])
  useEffect(() => {
    if (previousProps?.categoryDropdown !== categoryDropdown) {
      const startFrom = 0
      getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse, eventFilter, categoryFilter)
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    return () => {
      previousProps.categoryDropdown = categoryDropdown
    }
  }, [categoryDropdown])
  function onStatusUpdate () {
    // dispatch(updateEventDetails(updatedUsersData))
    setLoading(true)
    toggleWarning()
    setSelectedData({})
  }
  const processExcelExportData = (data) => data?.map((eventList, index) => {
    const srNo = index + 1
    const sName = eventList?.sName || '-'
    const sCategoryName = eventList?.oCategory?.sName || '-'
    const sSubCategoryName = eventList?.oSubCategory?.sName || '-'
    let dCreatedAt = moment(eventList?.dCreatedAt)?.local()?.format('MM/DD/YYYY hh:mm A')
    dCreatedAt = dCreatedAt === 'Invalid date' ? ' - ' : dCreatedAt
    const dLoginAt = eventList?.dLoginAt ? moment(eventList?.dLoginAt)?.local()?.format('lll') : '-'
    const ePlatform = eventList?.ePlatform ? (eventList?.ePlatform === 'I' ? 'iOS' : eventList?.ePlatform === 'W' ? 'Web' : eventList?.ePlatform === 'A' ? 'Android' : '-') : '-'
    let dDeletedAt = moment(eventList?.dDeletedAt)?.local()?.format('MM/DD/YYYY hh:mm A')
    dDeletedAt = dDeletedAt === 'Invalid date' ? ' - ' : dDeletedAt
    const sUtmSource = eventList?.oUtm?.sUtmSource || '-'
    return { ...eventList, dLoginAt, srNo, dCreatedAt, sName, ePlatform, dDeletedAt, sUtmSource, sCategoryName, sSubCategoryName }
  })

  async function onExport () {
    // if (startDate && endDate) {
    await getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, true)
    // setLoader(true)
    // } else {
    //     setMessage('Please Select Date Range')
    //     setModalMessage(true)
    //     setStatus(false)
    // }
  }

  function onRefresh () {
    getList(start, offset, sort, order, search, props?.filter, startDate, endDate, platform, utmSource, isFullResponse)
    setLoading(true)
    setPageNo(activePageNo)
  }

  useImperativeHandle(ref, () => ({
    onExport,
    onRefresh
  }))
  function warningWithDeleteMessage (Id, eCategory, type) {
    setType(type)
    setModalWarning(true)
    setDeleteId(Id)
  }
  function warningWithUpdateStatus (data) {
    setModalWarningEvent(true)
    setIsOpenEvent(data)
  }
  function onDelete () {
    dispatch(deleteEvent({ Id: deleteId }))
    setLoading(true)
    setTimeout(() => {
      getList(0, 10, 'dCreatedAt', 'desc', '', props?.filter, '', '', platform, utmSource, isFullResponse);
    }, 2000);
    toggleWarning()
  }
  function onCancel () {
    toggleWarning()
    setDeleteId('')

  }
  function onCancelPopup () {
    setIsOpenEvent('')
    toggleWarningEvent()
    setDeclareOutCome('')
  }
  function mapData (data, i) {
    const newData = columnNames?.filter(c => c?.bShow)
    const tableCells = []
    for (let k = 0; k < newData?.length; k++) {
      const column = newData[k].sColumnName
      switch (column) {
        case 'No.':
          tableCells.push(<td key={k}>{(((index - 1) * offset) + (i + 1))}</td>)
          break
        case 'Status':
          tableCells.push(
            <td key={k}>
              {
                data?.eStatus === 'c' ?
                  <Badge className='match-status-r ml-2' color='#FEECF0'>
                    Completed
                  </Badge>
                  : data?.eStatus === 'a' ? (<Badge tag="span" className='match-status-cancl ml-2' color='#E6FAEF' >Active</Badge>) :
                    data?.eStatus === 'd' ? (<Badge tag="span" className='match-status-p ml-2' color='#fcf2e8 '>Deleted</Badge>) :
                      data?.eStatus === 'i' ? (<Badge className='match-status-r ml-2' color='#FEECF0'>In Active</Badge>) :
                        data?.eStatus === 'p' ? (<Badge className='match-status-cmp ml-2' color='#e6faef'> Pending</Badge>) :
                          data?.eStatus === 's' ? (<Badge className='match-status-u ml-2' color='#f3ebff'>Started</Badge>) :
                            data?.eStatus === 'po' ? (<Badge className='match-status-r ml-2' color='#FEECF0'>Pending Outcome</Badge>) : ('')

              }
            </td>)
          break
        case 'Name':
          tableCells.push(
            <td key={k}>
              {data && data.sName ? (
                (() => {
                  const words = data.sName.split(' ');
                  let currentLine = '';
                  const lines = [];

                  // Loop through words and accumulate them until they exceed 40 characters
                  words.forEach((word) => {
                    const newLine = currentLine ? `${currentLine} ${word}` : word;
                    if (newLine.length <= 40) {
                      currentLine = newLine;
                    } else {
                      lines.push(currentLine);
                      currentLine = word; // Start a new line with the current word
                    }
                  });

                  // Push any remaining words to lines
                  if (currentLine) {
                    lines.push(currentLine);
                  }

                  return (
                    <div className='table-column-break-event'>
                      {lines.map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </div>
                  );
                })()
              ) : (
                data?.sName
              )}
            </td>
          )
          break
        case 'Start Date':
          tableCells.push(
            <td key={k}>
              {moment(data?.dStartDate)?.format('lll')}
            </td>
          )
          break
        case 'End Date':
          tableCells.push(<td key={k}> {moment(data?.dEndDate)?.format('lll')}</td>)
          break
        case 'Category':
          tableCells.push(<td key={k}>{data?.oCategory?.sName}
          </td>)
          break
        case 'Declare OutCome':
          tableCells.push(<td key={k}>
            {data?.eOutcomeMethod === 'm' ?
              <Button className='btn btn-primary' style={{ 'backgroundColor': '#3E4F93' }} onClick={() => warningWithUpdateStatus(data)}
                disabled={data?.eStatus === 'c' || data?.eStatus === 'i' || data?.eStatus === 'd' || data?.eStatus === 's' || data?.eStatus === 'p'}
              >
                Declare Outcome
              </Button>
              : '--'}
          </td>)
          break
        case 'Actions':
          tableCells.push(
            <td key={k}>
              <ul className="action-list mb-0 d-flex p-0">
                {((Auth && Auth === 'SUPER') || (adminPermission?.OT_EVENT !== 'R')) && (
                  <li>
                    <Button disabled={data?.eStatus === 'i' || data?.eStatus === 'a' || data?.eStatus === 'p' || data?.eStatus === 's' ? false : true} className="view" color="link" onClick={() => navigate(`/event-management/edit-event/${data?._id}`)}>
                      <Button className={'edit-btn-icon'} style={{ backgroundColor: `${data?.eStatus === 'i' || data?.eStatus === 'a' || data?.eStatus === 'p' ? '' : 'rgb(201 208 214)'}`, borderColor: `${data?.eStatus === 'i' || data?.eStatus === 'a' || data?.eStatus === 'p' ? '' : '#5c85aa'}` }} >
                        <img alt="View" src={editButton} />
                      </Button>
                    </Button>
                    <Button disabled={data?.eStatus === 's' || data?.eStatus === 'c' || data?.eStatus === 'po' ? true : false} className='delete-btn-icon ms-2' color="link" onClick={() => warningWithDeleteMessage(data?._id, data?.eCategory, 'Delete')}>
                      <span><img alt="Delete" src={deleteIcon} /></span>
                    </Button>
                    <Link state={data} to={{ pathname: '/event-management/event-details/' + data?._id }} >
                      <Button className='view-btn-icon ms-2'>
                        <img alt="View" src={viewIcon} />
                      </Button>
                    </Link>
                    <Link state={data} to={{ pathname: '/event-management/event-report/' + data?._id }} >
                      <Button className='view-report-btn-icon ms-2' >
                        <img alt="View" src={report} style={{ maxWidth: '30px!important' }} />
                      </Button>
                    </Link>
                  </li>
                )}
              </ul>
            </td>
          )
          break
        default:
          tableCells.push(<td key={k}>--</td>)
      }
    }

    return <tr key={i}>{tableCells}</tr>
  }
  function handleSubmitOutCome () {
    if (declareOutCome) {
      const yesOption = isOpenEvent?.aAnswerOptions?.find(option => option?.sValue === declareOutCome);
      const outComeId = yesOption?._id;
      const eventId = isOpenEvent?._id
      setDeclareOutComeErr('')
      dispatch(declareOutComeEvent({ eventId, outComeId }))
      setIsOpenEvent('')
      toggleWarningEvent()
      setDeclareOutCome('')
    } else {
      setDeclareOutComeErr('Required Field')
    }
  }

  return (
    <Fragment>
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <ExcelExport ref={exporter} data={fullList && fullList?.length > 0 ? fullList : list} fileName={`Event.xlsx`}>
        <ExcelExportColumn field='srNo' title='Sr No' />
        <ExcelExportColumn field='sName' title='Event Name' />
        {/* <ExcelExportColumn field='sDescription' title='Description' /> */}
        <ExcelExportColumn field='dStartDate' title='Start Date' />
        <ExcelExportColumn field='dEndDate' title='End Date' />
        <ExcelExportColumn field='sCategoryName' title='Category' />
        <ExcelExportColumn field='sSubCategoryName' title='SubCategory' />
        <ExcelExportColumn field='dCreatedAt' title='Created Date' />
      </ExcelExport>
      {loader && <Loading />}
      {!loading && list?.length === 0
        ? (<DataNotFound message="Event List" obj={obj} />)
        : (
          <div className='table-represent'>
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    {columnNames?.map((c, i) => {
                      if (c?.bShow) {
                        return <th key={i}>{c?.sColumnName}</th>
                      } else {
                        return null
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {list && list?.length && list?.map((data, i) => {
                    return (mapData(data, i)
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

      {list?.length !== 0 && (
        <PaginationComponent
          activePageNo={activePageNo}
          endingNo={endingNo}
          listLength={listLength}
          offset={offset}
          paginationFlag={paginationFlag}
          setListLength={setListLength}
          setLoading={setLoading}
          setOffset={setOffset}
          setPageNo={setPageNo}
          setStart={setStart}
          startingNo={startingNo}
          total={total}
        />
      )}

      <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
        <ModalBody className='text-center'>
          <img alt='check' className='info-icon' src={warningIcon} />
          <h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
          <Row className='row-12'>
            <Col>
              <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={deleteId ? onCancel : toggleWarning} type='submit'>Cancel</Button>
            </Col>
            <Col>
              <Button className='theme-btn danger-btn full-btn' onClick={deleteId ? onDelete : onStatusUpdate} type='submit'>{`${type} Now`}</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal className="modal-confirm" isOpen={modalWarningEvent} toggle={toggleWarningEvent}>
        <ModalBody className='text-center'>
          <h2 className='popup-modal-message mb-3'>Change Status</h2>
          <div className="d-flex inline-input  text-center mb-2 ms-4">
            <Label className='me-2'>Declare Outcome : </Label>
            <div className='custom-radio custom-control'>
              <Input
                type="radio"
                defaultChecked={declareOutCome === 'YES'}
                className='custom-control-input me-2'
                disabled={adminPermission?.OT_EVENT === 'R'}
                id="themeRadio1"
                label="Yes"
                name="themeRadio"
                value={'YES'}
                onChange={event => setDeclareOutCome(event.target?.value)}
              />
              <Label>Yes</Label>
            </div>
            <div className='custom-radio custom-control'>
              <Input
                type="radio"
                defaultChecked={declareOutCome === 'NO'}
                className='custom-control-input me-2'
                disabled={adminPermission?.OT_EVENT === 'R'}
                id="themeRadio2"
                label="No"
                name="themeRadio"
                value={'NO'}
                onChange={event => setDeclareOutCome(event.target?.value)}
              />
              <Label>No</Label>
            </div>
            <p className="error-text">{declareOutComeErr}</p>
          </div>
          <Row className='row-12'>
            <Col>
              <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={onCancelPopup} type='submit'>Cancel</Button>
            </Col>
            <Col>
              <Button className='theme-btn danger-btn full-btn' onClick={handleSubmitOutCome} type='submit'>{`Save`}</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  )
})

EventList.propTypes = {
  location: PropTypes.object,
  openPicker: PropTypes.bool,
  search: PropTypes.string,
  List: PropTypes.object,
  resStatus: PropTypes.bool,
  resMessage: PropTypes.string,
  getList: PropTypes.func,
  flag: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  searchBox: PropTypes.string,
  history: PropTypes.object,
  // filter: PropTypes.string,
  onRefresh: PropTypes.func,
  getDeletedUsers: PropTypes.func,
  setSearchProp: PropTypes.func,
  setFilter: PropTypes.func,
  setinitialFlag: PropTypes.func,
  setDateRange: PropTypes.func,
  dateFromTOdateTO: PropTypes.string,
  columnNames: PropTypes.array
}

EventList.displayName = EventList

export default EventList
